<template>
	<div>
		<e-select
			track-by="country_name"
			label="country_name"
			:options="countries_iso_array"
			:searchable="true"
			:allow-empty="false"
			:show-labels="false"
			:search="true"
			@select="indicatif"
			v-model="internalValue.indicatif"
		>
			<template slot="singleLabel">
				<img class="option__image mt-1" :src="require(`GroomyRoot/assets/img/flag-iso/${internalValue.indicatif.value}.png`)">
			</template>
			<template slot="option" slot-scope="props">
				<img class="option__image" :src="require(`GroomyRoot/assets/img/flag-iso/${props.option.value}.png`)">
				<span class="ml-2">{{ props.option.country_name }}</span>
			</template>
		</e-select>
	</div>
</template>

<script>
	import Vue from 'vue'
	import Countries from '@/assets/lang/countries/countries'
	import Indicatif from '@/assets/lang/countries/indicatif'
	
	export default {
		props: {
			value:{
				type: Object,
				default: () => ( {
					phone_country: 'FR',
					phone_indicatif: '+33',
					phone_id: '0',
				} )
			},

		},
		data () {
			return {
				indicatifs: {},
				countries: {},
				countries_iso: {},
				countries_iso_array: [],
			}
		},
		created() {
			this.indicatifs = Indicatif
			this.countries = Countries[Vue.i18n.locale()].label
			this.countries_iso = Countries[Vue.i18n.locale()].iso_3

			for (let [key, value] of Object.entries(this.countries_iso))
			{
				try{
					require(`GroomyRoot/assets/img/flag-iso/${value}.png`)
					this.countries_iso_array.push({
						id: key,
						value: value,
						country_name: this.countries[key]
					})
				}
				catch(ex){
					//On a pas le drapeau mais c'est des pays de merde
				}
			}
		},
		methods: {
			indicatif(selectdOption, id)
			{
				// this.form.phones[id].phone_indicatif = this.indicatifs[selectdOption.value]
				// this.form.phones[id].phone_country = selectdOption.value

				this.$emit('input', {
					...this.value, 
					phone_indicatif: this.indicatifs[selectdOption.value],
					phone_country: selectdOption.value
				})
			}
		},
		computed: {
			internalValue:{
				get () {
					return {
					...this.value,
					indicatif: {
						id: this.value.phone_id,
						value: this.value.phone_country,
					}
				}
				},
				set (val) {
					
				}
			},
		}
	}
</script>
